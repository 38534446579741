import './App.css';
import GoogleSheetData from './googlesheet';

function App() {
  return (
    <div className="App">
      <GoogleSheetData />
    </div>
  );
}

export default App;
